import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center ml-2 mr-2" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesHeaderButtons = _resolveComponent("SalesHeaderButtons")!
  const _component_SalesDataWrapper = _resolveComponent("SalesDataWrapper")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SalesDialog = _resolveComponent("SalesDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, null, {
          content: _withCtx(() => [
            _createVNode(_component_SalesHeaderButtons, {
              title: "Orders",
              "new-button-text": "New Order",
              dataTestButtonTag: "newSalesOrder",
              "print-button-text": "Print/Export",
              ShowTableViewButtons: true,
              showClearFilterButton: true,
              lastUpdated: _ctx.lastUpdated,
              onNewButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSales(false))),
              onIsResultView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isResultViewChange($event))),
              onPrintButtonClicked: _ctx.printExportData,
              onClearFilters: _ctx.clearFilters,
              showNewButton: !_ctx.isProduction
            }, null, 8, ["lastUpdated", "onPrintButtonClicked", "onClearFilters", "showNewButton"]),
            _createVNode(_component_SalesDataWrapper, {
              ref: "salesOrderTable",
              isResultView: _ctx.isResultView,
              onLastUpdated: _ctx.isDataUpdated,
              maxExportRows: 1000,
              printExportData: _ctx.printExportData,
              clearFilters: _ctx.clearFilters
            }, null, 8, ["isResultView", "onLastUpdated", "printExportData", "clearFilters"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_SalesDialog, {
      order: this.salesOrder,
      show: this.showSalesDialog,
      readOnly: this.readOnly,
      showLoadExistingQuoteBtn: true,
      onHide: _cache[2] || (_cache[2] = 
      (hidden) => {
        this.salesOrder = null;
        this.showSalesDialog = hidden;
      }
    ),
      onOnSave: _cache[3] || (_cache[3] = 
      (event) => {
        this.fetchFilteredOrders(false);
      }
    )
    }, null, 8, ["order", "show", "readOnly"])
  ], 64))
}